import React from "react"

const AboutLongTerm = () => {
  return (
    <div className="row align-items-center">
      <div className="col-lg-5">
        <figure>
          <img
            src="#"
            srcSet="/snowlake/images/concept/concept3.png 1x, /snowlake/images/concept/concept3@2x.png 2x"
            alt=""
          />
        </figure>
      </div>
      {/*/column */}
      <div className="space20 d-md-none" />
      <div className="space50 d-none d-md-block d-lg-none" />
      <div className="col-lg-7 pl-60 pl-md-15">
        <h3 className="display-2">Thinking Long Term</h3>
        <div className="space20" />
        <p>
          We won't just build your project to match your specifications and then
          walk away. We treat each product as our own and think carefully about
          long-term maintenance, and about what it would take to build on top of
          the work that's there.
        </p>
        <p>
          This makes it a lot easier and less costly if you want to make changes
          or add features to your app further down the line.
        </p>
        {/* <div className="space10" />
          <a href="#" className="btn btn-purple">
            More Details
          </a> */}
      </div>
      {/*/column */}
    </div>
  )
}

export default AboutLongTerm
