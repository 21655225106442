import React from "react"
import { Link } from "gatsby"

const CallToAction = () => {
  return (
    <div
      className="box shadow p-60 image-wrapper bg-full bg-image inverse-text rounded d-md-flex align-items-md-center justify-content-md-between"
      data-image-src="/snowlake/images/art/bg7.jpg"
    >
      <h3 className="display-3 mb-0">
        Convinced yet? Let's make something great together.
      </h3>
      <div className="space30 d-md-none"></div>
      <Link to="/contact" className="btn btn-white btn-strong-hover mb-0">
        Contact Us
      </Link>
    </div>
  )
}

export default CallToAction
