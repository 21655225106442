// @ts-check
import React from "react"

import Layout from "../components/layout/Layout"
import AboutText from "../partials/AboutText"
import AboutExperience from "../partials/AboutLongTerm"
import AboutClients from "../partials/AboutClients"
import CallToAction from "../components/CallToAction"
import ImageHeader from "../components/ImageHeader"

const AboutPage = () => (
  <Layout transparentHeader title="About Us">
    <ImageHeader
      imgSrc="/snowlake/images/art/bg4.jpg"
      title="Who we are"
      lead={`
      We're a London based independent studio and consultancy
      with a passion for crafting modern and high-performance
      mobile apps that offer beautiful user experiences.`}
      noGradient
    />
    <div className="wrapper gray-wrapper">
      <div className="container inner">
        <AboutText />
      </div>
    </div>

    <div className="wrapper white-wrapper">
      <div className="container inner">
        <AboutClients />
      </div>
    </div>

    <div className="wrapper light-wrapper">
      <div className="container inner">
        <AboutExperience />
        <div className="space90"></div>
        <CallToAction />
      </div>
    </div>
    {/* 
    <div className="wrapper light-wrapper">
      <div className="container inner">
        <AboutTeam />
      </div>
    </div> */}
  </Layout>
)

export default AboutPage
