import React from "react"

const AboutClients = () => {
  const clients = [
    "paypal.com",
    "bbcstudios.com",
    "hsbc.co.uk",
    "10xbanking.com",
    "eurostar.com",
    "captain.ai",
  ]

  return (
    <div>
      <h2 className="section-title mb-40 text-center">We've worked with</h2>
      <div className="row clients text-center">
        {clients.map(client => (
          <a
            href={`https://${client}`}
            target="blank"
            className="col-md-4 col-lg-2"
          >
            <figure className="pl-15 pr-15">
              <img src={`/images/clients/${client}.jpg`} alt="" />
            </figure>
          </a>
        ))}
      </div>
    </div>
  )
}

export default AboutClients
