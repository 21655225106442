import React from "react"

const AboutText = () => {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-lg-5">
          <figure>
            <img
              src="#"
              srcSet="/snowlake/images/concept/concept24.png 1x, /snowlake/images/concept/concept24@2x.png 2x"
              alt=""
            />
          </figure>
        </div>
        {/*/column */}
        <div className="space20 d-md-none" />
        <div className="space50 d-none d-md-block d-lg-none" />
        <div className="col-lg-7 pl-60 pl-md-15">
          <h3 className="display-2">Modern tools</h3>
          <div className="space20" />
          <p>
            We believe that using cutting-edge technology is vital to the
            success of your project as it allows you to maximise what you can do
            with a mobile app and rapidly iterate. We specialise in using the
            React Native framework which allows us to both write clean and
            maintainable code as well as deliver projects much quicker than
            traditional mobile app development tools.
          </p>
          {/* <div className="space10" />
          <a href="#" className="btn btn-purple">
            More Details
          </a> */}
        </div>
        {/*/column */}
      </div>
      {/*/.row */}
      <div className="space120" />
      <div className="row align-items-center">
        <div className="col-lg-5 order-lg-2">
          <figure>
            <img src="/snowlake/images/concept/concept20.png" alt="" />
          </figure>
        </div>
        {/*/column */}
        <div className="space20 d-md-none" />
        <div className="space50 d-none d-md-block d-lg-none" />
        <div className="col-lg-7 pr-60 pr-md-15">
          <h3 className="display-2">Modern thinking</h3>
          <div className="space20" />
          <p>
            We started Edamame Studios because we love building great mobile
            experiences. Operating as a startup means less internal bureaucracy
            and more results.
          </p>
          <p>
            <em>But that doesn't come at the cost of experience </em> - we have
            decades of software engineering experience between us and have
            worked with startups and enterprises alike. We've held CTO and
            senior management roles, and have a proven track record of working
            as a team. Agile software development models increases our
            development speed and allows us to build together with you, rather
            than ask for everything up front.
          </p>
          {/* <div className="space10" />
          <a href="#" className="btn btn-yellow">
            More Details
          </a> */}
        </div>
        {/*/column */}
      </div>
      {/*/.row */}
    </>
  )
}

export default AboutText
